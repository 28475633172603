<template>
    <!-- 发货单核对 -->
    <div class="business-accounting" @click.prevent="clearHover" v-loading="listLoading">
        <div class="transportationCheckBoxs">
            <div class="query-criteria">
                <div>
                    <p>场站：</p>
                    <el-select v-model="station" clearable placeholder="请选择">
                        <template v-for="item in stationList">
                            <el-option :label="item.name" :value="item.station_id" :key="item.id"></el-option>
                        </template>
                    </el-select>
                </div>
                <div>
                    <p>核对状态：</p>
                    <el-select v-model="check_status" clearable placeholder="请选择">
                        <el-option label="未核对" value="1"></el-option>
                        <el-option label="已核对" value="2"></el-option>
                        <el-option label="部分核对" value="3"></el-option>
                    </el-select>
                </div>
                <div>
                    <p>提交状态：</p>
                    <el-select v-model="approval_status" clearable placeholder="请选择">
                        <el-option label="未提交" value="1"></el-option>
                        <el-option label="已提交" value="2"></el-option>
                        <el-option label="已完成" value="3"></el-option>
                        <el-option label="已退回" value="4"></el-option>
                    </el-select>
                </div>
                <div>
                    <p>发货时间段：</p>
                    <el-date-picker
                        v-model="fhTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </div>
                <div>
                    <p>工程名称：</p>
                    <el-input :title="gcmc" v-model="gcmc" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>客户名称：</p>
                    <el-input :title="khmc" v-model="khmc" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>合同编号：</p>
                    <el-input :title="khmc" v-model="contractNumber" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>搜索：</p>
                    <el-input :title="khmc" v-model="searchKey" placeholder="请输入内容"></el-input>
                </div>

                <div>
                    <el-button @click="getHeDuiList('queryList')" type="primary">
                        查询
                    </el-button>
                </div>
            </div>
            <div>
                <el-button
                    class="bulk-send"
                    type="primary"
                    size="small"
                    @click="bulkSend"
                >
                    批量提交
                </el-button>
            </div>
            <div class="table-list">
                <el-table
                    :data="tableData"
                    height="100%"
                    stripe
                    show-summary
                    ref="transportationCheckTable"
                    @selection-change="handleSelectionChange"
                    header-row-class-name="tableHeader"
                    @row-click="getClickLine"
                    :summary-method="getSum"
                    :row-class-name="tableRowClassName"
                    v-scroll="scrollFn"
                    @sort-change="sortChange"
                >
                    <el-table-column
                        label="选择"
                        type="selection"
                        width="60"
                    >
                    </el-table-column>
                    <el-table-column
                        width="200"
                        label="发货单编号"
                        show-overflow-tooltip
                        sortable="custom"
                        prop="delivery_receipt_number"
                    >
                        <template slot-scope="scope">
                            <p @click.stop="awakenAlert(scope.row)" class="yunshuNumber">
                                {{ scope.row.delivery_receipt_number }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="任务单编号"
                        show-overflow-tooltip
                        width="180"
                        sortable="custom"
                        prop="task_number"
                    >
                        <template slot-scope="scope">
                            <el-popover
                                v-model="scope.row.show"
                                placement="right"
                                popper-class="business-accounting-popover"
                                trigger="focus"
                            >
                                <div
                                    class="click-look-details-check"
                                    :data="scope.row"
                                >
                                    <div>
                                        <p>发货单编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.delivery_receipt_number" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.delivery_receipt_number }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>任务单编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.task_number" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.task_number }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>工程名称</p>
                                        <el-tooltip :enterable="false" :content="scope.row.engineering_name" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.engineering_name }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>施工单位</p>
                                        <p>{{ scope.row.sgdw_name }}</p>
                                    </div>
                                    <div>
                                        <p>客户名称</p>
                                        <p>{{ scope.row.client_name }}</p>
                                    </div>
                                    <div>
                                        <p>站点</p>
                                        <p>{{ scope.row.station_name }}</p>
                                    </div>
                                    <div>
                                        <p>强度等级</p>
                                        <p>{{ scope.row.strength_grade_name }}</p>
                                    </div>
                                    <div>
                                        <p>附加型号</p>
                                        <p>{{ scope.row.special_require_name }}</p>
                                    </div>
                                    <div>
                                        <p>任务车次</p>
                                        <p>{{ scope.row.task_trains }}</p>
                                    </div>
                                    <div>
                                        <p>车牌号</p>
                                        <p>{{ scope.row.plate_number }}</p>
                                    </div>
                                    <div>
                                        <p>发货时间</p>
                                        <p>{{ scope.row.deliver_time }}</p>
                                    </div>
                                    <div>
                                        <p>发货方量</p>
                                        <p>{{ scope.row.deliver_volume }}</p>
                                    </div>
                                    <div>
                                        <p>剩余方量</p>
                                        <p>{{ scope.row.surplus_volume }}</p>
                                    </div>
                                    <div>
                                        <p>退货方量</p>
                                        <p>{{ scope.row.back_volume }}</p>
                                    </div>
                                    <div>
                                        <p>签收时间</p>
                                        <p>{{ scope.row.sign_time }}</p>
                                    </div>
                                    <div>
                                        <p>签收方量</p>
                                        <p>{{ scope.row.sign_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后签收方量</p>
                                        <p>{{ scope.row.adjusted_sign_volume }}</p>
                                    </div>
                                    <div>
                                        <p>盈亏方量</p>
                                        <p>{{ scope.row.overage_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后盈亏</p>
                                        <p>{{ scope.row.adjusted_overage_volume }}</p>
                                    </div>
                                    <div>
                                        <p>浇筑方式</p>
                                        <p>{{ scope.row.pouring_name }}</p>
                                    </div>
                                    <div>
                                        <p>泵送高度</p>
                                        <p>{{ scope.row.pump_height }}</p>
                                    </div>
                                    <div>
                                        <p>泵送方量</p>
                                        <p>{{ scope.row.pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后泵送方量</p>
                                        <p>{{ scope.row.adjusted_pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>非泵方量</p>
                                        <p>{{ scope.row.non_pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后非泵方量</p>
                                        <p>{{ scope.row.adjusted_non_pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>砂浆方量</p>
                                        <p>{{ scope.row.mortar_volume }}</p>
                                    </div>
                                    <div>
                                        <p>砂浆调整后方量</p>
                                        <p>{{ scope.row.adjusted_mortar_volume }}</p>
                                    </div>
                                    <div>
                                        <p>补方</p>
                                        <p>{{ scope.row.replenish_quantity }}</p>
                                    </div>
                                    <div>
                                        <p>调整后补方</p>
                                        <p>{{ scope.row.adjusted_replenish_quantity }}</p>
                                    </div>
                                    <div>
                                        <p>运输单位</p>
                                        <p>{{ scope.row.traffic_unit }}</p>
                                    </div>
                                    <div>
                                        <p>运距</p>
                                        <p>{{ scope.row.haul_distance }}</p>
                                    </div>
                                    <div>
                                        <p>调整原因</p>
                                        <p>{{ scope.row.adjusted_reason }}</p>
                                    </div>
                                    <div>
                                        <p>核对状态</p>
                                        <p>{{ scope.row.check_status_name }}</p>
                                    </div>
                                    <div>
                                        <p>提交状态</p>
                                        <p>{{ scope.row.approval_status | approvalStatus }}</p>
                                    </div>
                                </div>
                                <span class="table-in-table-span" slot="reference">
                                    {{ scope.row.task_number }}
                                </span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="engineering_name"
                        label="工程名称"
                        show-overflow-tooltip
                        width="120"
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="sgdw_name"
                        label="施工单位"
                        show-overflow-tooltip
                        width="120"
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="client_name"
                        label="客户名称"
                        show-overflow-tooltip
                        width="120"
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        width="80"
                        prop="station_name"
                        label="站点"
                        show-overflow-tooltip
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        label="产品标号"
                        width="100"
                        show-overflow-tooltip
                        sortable="custom"
                        prop="strength_grade_name"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.strength_grade_name }}{{ scope.row.special_require_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="task_trains"
                        label="任务车次"
                        width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="plate_number"
                        label="车牌号"
                        width="100"
                        show-overflow-tooltip
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="deliver_time"
                        label="发货时间"
                        width="160"
                        show-overflow-tooltip
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="deliver_volume"
                        label="发货方量"
                        width="100"
                        show-overflow-tooltip
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="sign_volume"
                        label="签收方量"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adjusted_sign_volume"
                        label="调整后签收方量"
                        width="120"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="pump_volume"
                        label="泵送方量"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adjusted_pump_volume"
                        label="调整后泵送方量"
                        width="120"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="replenish_quantity"
                        label="补方"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adjusted_replenish_quantity"
                        label="调整后补方"
                        width="120"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="check_status_name"
                        label="核对状态"
                        width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        label="提交状态"
                        width="80"
                        show-overflow-tooltip
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.approval_status | approvalStatus }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="data-display">
                <div class="el_left multipleSelected">
                    已选 <span>{{ selectList.length }}</span> 条
                </div>
                共<span>{{ listLength }}</span>条数据，已加载<span>{{ loaded }}</span>条
            </div>
        </div>
    </div>
</template>

<script>
// 运输单核对
export default {
    directives: {
        scroll: {
            inserted: function (el, binding) {
                const cb = binding.value;
                const selectWrap = el.querySelector('.el-table__body-wrapper');
                selectWrap.addEventListener('mousewheel', function (e) {
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    const direction = e.deltaY > 0 ? 'down' : 'up';
                    cb(selectWrap, direction, scrollDistance);
                });
            },
        },
    },
    data() {
        return {
            checkList: [],
            tableData: [],
            listLength: 0,
            station: '',
            stationList: [],
            check_status: '',
            approval_status: '',
            gcmc: '',
            khmc: '',
            fhTime: [],
            contractNumber: '',
            searchKey: '',
            loaded: 0,
            listLoading: false,
            count: 1,
            allData: false,
            selectList: [],
            hoverLineData: {},
            orderby: '',
        };
    },
    mounted() {
        // 拉取所有场站
        this.getAllStation();
        this.getHeDuiList();
    },
    filters: {
        // eslint-disable-next-line consistent-return
        approvalStatus(value) {
            switch (value) {
                case 1:
                    return '未提交';
                case 2:
                    return '已提交';
                case 3:
                    return '已完成';
                case 4:
                    return '已退回';
                case 5:
                    return '退回后已提交';
            }
        },
    },
    methods: {
        // 鼠标滚动加载更多
        scrollFn: function (obj, direction, scrollTop) {
            if (direction === 'up' || this.allData === true || this.listLoading === true) {return;}
            if (scrollTop <= 150) {
                if (this.tableData.length < this.listLength) {
                    this.getHeDuiList('pullUp');
                }
            }
        },
        tableRowClassName({ row }) {
            let newClass = '';
            if (row.deliver_volume !== row.sign_volume || row.other_exceptions) {
                newClass = 'row_abnormal';
            }
            return newClass;
        },
        // 拉取所有场站
        getAllStation() {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            const BranchId = this.$takeTokenParameters('BranchId');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=' + CompanyId + '&branchId=' + BranchId)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        getSum(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value)) && index > 10) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                    if (column.label === '车牌号') {
                        sums[index] = '';
                    } else if (column.label === '任务车次') {
                        sums[index] = '';
                    } else if (column.label === '附加型号') {
                        sums[index] = '';
                    } else {
                        sums[index] = sums[index].toFixed(2);
                    }
                } else {
                    sums[index] = ' ';
                }
            });
            return sums;
        },
        // 获取点击行
        getClickLine(row, column, event) {
            event.stopPropagation();
            this.tableData.forEach(item => {
                item.show = false;
            });
            this.hoverLineData = row;
            row.show = true;
        },
        // 清除滑动
        clearHover(event) {
            event.preventDefault();
            event.stopPropagation();
            this.clearAlertShow();
        },
        // 清除点击方法
        clearAlertShow() {
            this.tableData.forEach(item => {
                item.show = false;
            });
        },
        // 唤醒弹窗
        awakenAlert(row) {
            this.clearAlertShow();
            if (row.approval_status === 1 || row.approval_status === 4) {
                this.$HsAndHdLayer('FormCode=xs_accounts_ysd_hd', row.id, '核对完成', 'he');
            } else {
                this.$YWShowLayer('FormCode=xs_accounts_ysd_hd&IsView=true', row.id);
            }
        },
        // 列表多选
        handleSelectionChange(value) {
            this.selectList = value;
        },
        // 批量提交
        bulkSend() {
            this.listLoading = true;
            const idList = [];
            if (this.selectList.length === 0) {
                this.$message({
                    message: '请先选择要提交的发货单！',
                    type: 'warning',
                });
                return;
            }
            this.selectList.forEach(item => {
                if (item.id) {
                    idList.push(item.id);
                }
            });
            this.$axios
                .put('/interfaceApi/sale/accounts/delivery_receipt_batch_submit', idList)
                .then(() => {
                    this.$message.success('提交成功');
                    this.getHeDuiList('queryList');
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取核对列表
        getHeDuiList(status) {
            this.listLoading = true;
            if (status === 'pullUp') {
                this.count++;
            } else if (status === 'queryList') {
                this.count = 1;
                this.tableData = [];
                this.allData = false;
            }
            this.$axios
                .post('/interfaceApi/sale/accounts/delivery_check_page', {
                    check_status: this.check_status,
                    approval_status: this.approval_status,
                    engineering_name: this.gcmc,
                    client_name: this.khmc,
                    mixstation_code: this.station,
                    contract_number: this.contractNumber,
                    search_key: this.searchKey,
                    deliver_time_start: this.fhTime ? this.fhTime[0] : '',
                    deliver_time_end: this.fhTime ? this.fhTime[1] : '',
                    pageindex: this.count,
                    pagesize: 100,
                    orderby: this.orderby,
                })
                .then(res => {
                    this.listLength = res.total;
                    this.tableData.push(...res.rows);
                    this.loaded = this.tableData.length;
                    if (this.loaded >= this.listLength) {
                        this.allData = true;
                    }
                    this.$nextTick(() => {
                        if (this.$refs.transportationCheckTable) {
                            this.$refs.transportationCheckTable.doLayout();
                        }
                    });
                    this.listLoading = false;
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 服务端通用排序
        sortChange({ prop, order }) {
            if (order) {
                const sort = order === 'ascending' ? 'asc' : 'desc';
                this.orderby = prop + ' ' + sort;
            } else {
                this.orderby = '';
            }
            this.getHeDuiList('queryList');
        },
    },
};

</script>
<style lang='stylus'>
.transportationCheckBoxs
    background #fff
    width 100%
    height 100%
    padding .14rem
    padding-bottom 0
    overflow-y auto
    flex-direction column
    .query-criteria
        display flex
        align-items center
        flex-wrap wrap
        height 0.95rem
        border-bottom 1px solid #E7E7E7
        >div
            display flex
            align-items center
            margin-bottom 0.1rem
            margin-right: .2rem;
            p
                font-size .16rem
                white-space nowrap
                color #8593A7
            .el-input
                input
                    padding-left .1rem
            .el-select
                width 2rem
            .el-date-editor
                width 3rem
            .el-button
                background #2978ff
                color #fff
                width .9rem
                height .35rem
                display flex
                align-items: center;
                justify-content: center;
                border-color #2978ff
                span
                    line-height 0
    .bulk-send
        color #fff
        font-size .16rem
        margin .1rem 0
        padding 0 0.15rem
        height 0.36rem
    .table-list
        height calc(100% - 2.1rem)
        .yunshuNumber
            cursor:pointer;
            color blue
            text-decoration underline
        .el-table__header-wrapper
            border-left: 1px solid #f3f3f3;
            border-right: 1px solid #f3f3f3;
        .el-table__row>td
            border none
        .el-table::before//去掉最下面的那一条线
            height 0px
        .el-table
            overflow visible !important
            th,td
                text-align center
                height .36rem
                padding 0
            .tableHeader
                height 0.4rem
                th
                    background #EDF0F5
                    color #022782
            .el-table__body-wrapper
                border-left 1px solid #f3f3f3
                border-right 1px solid #f3f3f3
                .cell
                    color #404652
            .el-table__footer-wrapper
                height .36rem
                border-left 1px solid #f3f3f3
                border-right 1px solid #f3f3f3
                border-bottom 1px solid #f3f3f3
                td,th
                    background #FEF9E4
                    border none
                    div
                        color #FF6600 !important
                        font-weight 800
            .row_abnormal
                td
                    background #FFF1F0
                    border-top 1px solid #FFA39E
                    border-bottom 1px solid #FFA39E
                    &:first-child
                        border-left 1px solid #FFA39E
                    &:last-child
                        border-right 1px solid #FFA39E
                    .cell
                        color #FF4D4F
    .data-display
        width 100%
        display flex
        justify-content flex-end
        font-size .14rem
        color #979EA7
        height .5rem
        align-items center
        letter-spacing .02rem
        span
            font-size .18rem
            font-weight 800
            margin-top -.02rem
        span:first-child
            color #2978ff
        span:last-child
            color #D40004
        .multipleSelected
            color #979ea7
            font-size 0.13rem
            line-height 0.26rem
            margin-right 0.2rem
            span
                color #d40004
                font-size 0.18rem
                padding 0 0.1rem
.business-accounting-popover
        width 12.6rem !important;
        .table-in-table-span
            cursor:pointer;
            color:#333333 !important
        .click-look-details-check
            display flex
            flex-wrap wrap
            div
                display flex
                width 4rem
                height .4rem
                border 1px solid #E7E7E7
                margin 0 .05rem .05rem 0
                p:first-child
                    width 1.7rem
                    background #F2F6FC
                    color #022782
                    display flex
                    align-items center
                    padding 0 .2rem
                    font-weight 800
                    font-size: .14rem;
                p:last-child
                    width 2.3rem
                    font-size .14rem
                    padding .2rem
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis
                    margin-top: -0.12rem;
</style>
